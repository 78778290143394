import { toast } from "react-hot-toast";
import { ApiController } from "../apiController";
import { loader, notifySuccess, notifyError } from "../helpers/helper";

import {
  ChangePassword,
  ForgotPasswordParams,
  IntermediaryLogin,
  LoginRequest,
  Organisation,
  Rating,
  User,
} from "../swagger/models";

export const AuthStore = (set: any, get: any, store: any) => ({
  loginRequest: {},
  notifications: [],
  errors: [],
  isTwoFactorEnabled: false,
  newProfileCreated: false,
  newMPCreated: false,
  newPasswordSet: false,
  passwordUpdated: false,
  selectMIIProfiles: [],
  intermediarySentOTP: false,
  intermediaryLoginRequest: {},
  intermediaries: [],
  users: [],
  orgProfiles: [],
  isPasswordUpdated: false,
  subCategoryMasters: [],
  miis: [],
  orgId: 0,
  isLoading: false,
  /**
   *
   * @param loginRequest
   */
  login: async (loginRequest: LoginRequest) => {
    const toastId = loader("Logging in...");
    set({
      isLoading: true
    })
    try {

      const userData = await ApiController.getInstance().openApi.login(
        loginRequest
      );
      ApiController.getInstance().updateUserProfile(userData.data);
      ApiController.getInstance().initApis(userData.data.token);
      ApiController.getInstance().updateLoggedInStatus(userData.data);
      toast.dismiss(toastId);
      set({
        isLoading: false
      })
    } catch (error: any) {
      set({
        isLoading: false
      })
      toast.dismiss(toastId);
      notifyError(error?.response?.data?.message);
    }
  },
  intermediaryLoginAPI: async (loginRequest: IntermediaryLogin) => {
    const toastId = loader("Logging in...");
    try {
      const userData = (
        await ApiController.getInstance().openApi.intermediaryLogin(
          loginRequest
        )
      ).data;
      if (userData.User) {
        ApiController.getInstance().updateUserProfile(userData.User);
        ApiController.getInstance().initApis(userData.User.token);
        ApiController.getInstance().updateLoggedInStatus(userData.User);
        set({
          intermediarySentOTP: false,
          selectMIIProfiles: userData.User.UserParents,
        });
      } else {
        ApiController.getInstance().updateUserProfile(null);
        ApiController.getInstance().initApis(null);
        ApiController.getInstance().updateLoggedInStatus(null);
        set({
          intermediarySentOTP: true,
          selectMIIProfiles: userData.UserParents,
        });
      }
      toast.dismiss(toastId);
    } catch (error: any) {
      toast.dismiss(toastId);
      notifyError(error?.response?.data?.message);
    }
  },
  setIntermediaryLogin: async (loginRequest: IntermediaryLogin) => {
    set({
      intermediaryLoginRequest: loginRequest,
    });
  },
  register: async (userDetail: User) => {
    const toastId = loader("Creating Account...");
    set({
      isLoading: true
    })
    try {
      await ApiController.getInstance().openApi.register(
        userDetail
      );
      set({
        newProfileCreated: true,
      });
      toast.dismiss(toastId);
      notifySuccess(
        "You have registered successfully. Please check your email for further steps."
      );
      set({
        isLoading: false
      })
    } catch (error: any) {
      set({
        isLoading: false
      })
      toast.dismiss(toastId);
      notifyError(error?.response?.data?.message);
    }
  },
  /**
   * Function to call Forgot Password API
   * @param forgotPasswordObject
   */
  forgotPassword: async (forgotPasswordObject: ForgotPasswordParams) => {
    const toastId = loader("Sending email...");
    try {
      await ApiController.getInstance().openApi.forgotPassword(
        forgotPasswordObject
      );
      toast.dismiss(toastId);
      notifySuccess("An email has been sent to the email address. If you don't receive one shortly, please contact the administrator.");
    } catch (error: any) {
      console.log(error);
      toast.dismiss(toastId);
      notifyError(error?.response?.data?.message);
    }
  },
  /**
   *
   */
  getUserProfile: async () => {
    try {
      await ApiController.getInstance().authApi.getProfile();
    } catch (error: any) {
      notifyError(error?.response?.data?.message);
    }
  },
  /**
   *
   */
  getNotifications: async () => {
    const toastId = loader("Loading Notifications...");
    try {
      const userData =
        await ApiController.getInstance().authApi.listNotifications();
      set({
        notifications: userData.data,
      });
      toast.dismiss(toastId);
    } catch (error: any) {
      toast.dismiss(toastId);
      notifyError(error?.response?.data?.message);
    }
  },
  /**
   *
   * @param notificationId
   */
  cleanSingleNotifications: async (notificationId: number) => {
    const toastId = loader("Clearing Notification...");
    try {
      const userData =
        await ApiController.getInstance().authApi.cleanNotification(
          notificationId
        );
      set({
        notifications: userData.data,
      });
      toast.dismiss(toastId);
    } catch (error: any) {
      toast.dismiss(toastId);
      notifyError(error?.response?.data?.message);
    }
  },
  /**
   *
   */
  cleanAllNotifications: async () => {
    const toastId = loader("Clearing All Notifications...");
    try {
      await ApiController.getInstance().authApi.cleanAllNotifications();
      set({
        notifications: [],
      });
      toast.dismiss(toastId);
    } catch (error: any) {
      toast.dismiss(toastId);
      notifyError(error?.response?.data?.message);
    }
  },
  /**
   * Function to call Change Password API
   * @param changePassword
   */
  authChangePassword: async (changePassword: ChangePassword) => {
    const toastId = loader("Updating Password...");
    try {
      await ApiController.getInstance().authApi.changePassword(
        changePassword
      );
      toast.dismiss(toastId);
      notifySuccess("Change Password Successfully!");
      set({
        isPasswordUpdated: true,
      });
    } catch (error: any) {
      toast.dismiss(toastId);
      notifyError(error?.response?.data?.message);
    }
  },
  /**
   * Function to call Change Password API
   * @param userObject
   */
  updateProfile: async (userObject: User) => {
    const toastId = loader("Updating Profile...");
    try {
      await ApiController.getInstance().authApi.updateProfile(
        userObject
      );
      toast.dismiss(toastId);
      const user = await ApiController.getInstance().authApi.getProfile();
      ApiController.getInstance().updateUserProfile(user.data);
      notifySuccess("Updated Profile Successfully!");
    } catch (error: any) {
      toast.dismiss(toastId);
      notifyError(error?.response?.data?.message);
    }
  },
  /**
   * Function to call Change Password API
   * @param changePassword
   */
  openChangePassowrd: async (changePassword: ChangePassword) => {
    const toastId = loader("Updating Password...");
    try {
      await ApiController.getInstance().openApi.resetPassword(
        changePassword
      );
      set({
        passwordUpdated: true,
      });
      toast.dismiss(toastId);
      notifySuccess("Change Password Successfully!");
    } catch (error: any) {
      toast.dismiss(toastId);
      notifyError(error?.response?.data?.message);
    }
  },
  /**
   * Function to call Change Password API
   * @param changePassword
   */
  setupNewAccountPassword: async (changePassword: ChangePassword) => {
    const toastId = loader("Setting up Password...");
    try {
      await ApiController.getInstance().openApi.newAccountPassword(
        changePassword
      );
      set({
        newPasswordSet: true,
      });
      toast.dismiss(toastId);
      notifySuccess("Password updated Successfully!");
    } catch (error: any) {
      toast.dismiss(toastId);
      notifyError(error?.response?.data?.message);
    }
  },
  /**
   * Function to call Change Password API
   * @param changePassword
   */
  setupNewIntermediaryAccountPassword: async (
    changePassword: ChangePassword
  ) => {
    const toastId = loader("Setting up Password...");
    try {
      await ApiController.getInstance().openApi.intermediaryNewAccountPassword(
        changePassword
      );
      set({
        newPasswordSet: true,
      });
      toast.dismiss(toastId);
      notifySuccess("Password updated Successfully!");
    } catch (error: any) {
      toast.dismiss(toastId);
      notifyError(error?.response?.data?.message);
    }
  },
  listIntermediaries: async (intermediarysearchTerm: string) => {
    const toastId = loader("Loading Intermediaries...");
    try {
      const intermediaries =
        await ApiController.getInstance().openApi.allIntermediaries(
          intermediarysearchTerm
        );
      set({
        intermediaries: intermediaries.data,
      });
      toast.dismiss(toastId);
    } catch (error: any) {
      toast.dismiss(toastId);
      notifyError(error?.response?.data?.message);
    }
  },
  registerIntermediaryApi: async (orgId: number) => {
    const toastId = loader("Creating Account...");
    try {
      await ApiController.getInstance().openApi.triggerIntermediaryVerification(
        orgId
      );
      toast.dismiss(toastId);
      notifySuccess(
        "Verification Email sent successfully. Please check your email for further steps."
      );
    } catch (error: any) {
      toast.dismiss(toastId);
      notifyError(error?.response?.data?.message);
    }
  },
  loadAllOrgProfiles: async (orgId: number) => {
    try {
      const userData =
        await ApiController.getInstance().authApi.allLinkedProfiles();
      set({
        orgProfiles: userData.data,
      });
    } catch (error: any) {
      notifyError(error?.response?.data?.message);
    }
  },
  /**
   * Function to Update the notification preferences
   * @param emailPreference
   * @param smsPreference
   */
  updateNotificationPreference: async (
    emailPreference: boolean,
    smsPreference: boolean
  ) => {
    const toastId = loader("Updating Preferences...");
    try {
      await ApiController.getInstance().authApi.notificationPreference(
        emailPreference,
        smsPreference
      );
      toast.dismiss(toastId);
      const user = await ApiController.getInstance().authApi.getProfile();
      ApiController.getInstance().updateUserProfile(user.data);
      notifySuccess("Updated Preferences Successfully!");
    } catch (error: any) {
      console.log(error)
      toast.dismiss(toastId);
      notifyError(error?.response?.data?.message);
    }
  },
  /**
  * Function to capture the rating of the user
  * @param rating
  */
  captureRating: async (
    rating: Rating,
  ) => {
    const toastId = loader("Capturing Rating...");
    try {
      await ApiController.getInstance().authApi.rating(
        rating,
      );
      toast.dismiss(toastId);
      notifySuccess("Rating captured Successfully!");
    } catch (error: any) {
      console.log(error)
      toast.dismiss(toastId);
      notifyError(error?.response?.data?.message);
    }
  },
  /**
 * Function to capture the rating of the user
 * @param rating
 */
  listSubCategoryMasters: async () => {
    const toastId = loader("Fetching Categories...");
    try {
    const categories = (await ApiController.getInstance().indexApi.subcategoryMasterList()).data;
      toast.dismiss(toastId);
      set({
        subCategoryMasters: categories,
      });
    } catch (error: any) {
      console.log(error)
      toast.dismiss(toastId);
      notifyError(error?.response?.data?.message);
    }
  },
  /**
   * 
   * @param orgDetails 
   */
  marketParticipantRegistration: async (orgDetails: Organisation) => {
    const toastId = loader("Creating Account...");
    try {
      await ApiController.getInstance().openApi.marketParticipantRegistration(
        orgDetails
      );
      set({
        newMPCreated: true,
      });
      toast.dismiss(toastId);
      notifySuccess(
        "You have registered successfully. Please check your email for further steps."
      );
    } catch (error: any) {
      toast.dismiss(toastId);
      notifyError(error?.response?.data?.message);
    }
  },
  /**
   *
   */
  listMIIs: async () => {
    const toastId = loader("Loading Organisations...");
    try {
      const orgData =
        await ApiController.getInstance().openApi.listMiis();
      set({
        miis: orgData.data,
      });
      toast.dismiss(toastId)
    } catch (error: any) {
      toast.dismiss(toastId)
      notifyError(error?.response?.data?.message);
    }
  },
  /**
   *
   */
  verifyMPRegistrationToken: async (token: string) => {
    const toastId = loader("Verifying token...");
    try {
      const orgData =
        await ApiController.getInstance().openApi.verifyMpRegistrationLink(token);
      set({
        orgId: orgData.data.orgId,
      });
      toast.dismiss(toastId)
    } catch (error: any) {
      toast.dismiss(toastId)
      notifyError(error?.response?.data?.message);
    }
  },
  /**
   * 
   * @param orgDetails 
   */
  marketParticipantReRegistration: async (orgDetails: Organisation) => {
    const toastId = loader("Creating Account...");
    try {
      await ApiController.getInstance().openApi.marketParticipantReRegistration(
        orgDetails
      );
      set({
        newMPCreated: true,
      });
      toast.dismiss(toastId);
      notifySuccess(
        "You have registered successfully. Please check your email for further steps."
      );
    } catch (error: any) {
      toast.dismiss(toastId);
      notifyError(error?.response?.data?.message);
    }
  },
  verifyRegisteredInvestorApi: async (orgId: number) => {
    const toastId = loader("Creating Account...");
    set({
      isLoading: true
    })
    try {
      await ApiController.getInstance().openApi.triggerInvestorVerification(
        orgId
      );
      toast.dismiss(toastId);
      notifySuccess(
        "Verification Email sent successfully. Please check your email for further steps."
      );
      set({
        isLoading: false
      })
    } catch (error: any) {
      set({
        isLoading: false
      })
      toast.dismiss(toastId);
      notifyError(error?.response?.data?.message);
    }
  },
  listUser: async (investorSearchTerm: string) => {
    const toastId = loader("Loading profile...");
    try {
      const users =
        await ApiController.getInstance().openApi.investorProfile(
          investorSearchTerm
        );
      set({
        users: users.data,
      });
      toast.dismiss(toastId);
    } catch (error: any) {
      toast.dismiss(toastId);
      notifyError(error?.response?.data?.message);
    }
  }
});
