import { yupResolver } from "@hookform/resolvers/yup"
import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { Link, useNavigate, useParams } from "react-router-dom"
import { ApiController } from "../../../apiController"
import { newUserValidationSchema } from "../../../helpers/validation"
import { useCombinedStore } from "../../../store"
import { User } from "../../../swagger/models"
import { CityType, isValidEmail, isValidPhoneNumber, StateType } from "../../common-module/helper"
import { useTranslation } from 'react-i18next';

/**
 * This component is used to manage user settings.
 * It shows a form to either edit or add a user.
 * The form includes fields for name, email, phone number, PAN number, address, state, city, and pincode.
 * The user can either cancel or save the changes.
 * The component also handles the validation of the form fields.
 * If the form is valid, it calls the add or update user API based on whether the user is editing or adding a new user.
 * If the API call is successful, it redirects the user to the user management page.
 */
function UserSettings() {
    const { t } = useTranslation();
    const [userDetail, setUserDetails] = useState<User>({ roleId: 3 })
    const params = useParams()
    const userId: string = params.userId!
    const navigate = useNavigate()

    const userProfile = useCombinedStore((state: any) => state.userDetail)
    const addUserProfile = useCombinedStore((state: any) => state.addUserProfile)
    const updateUserProfile = useCombinedStore((state: any) => state.updateUserProfile)
    const getUser = useCombinedStore((state: any) => state.getUser)
    const currentURL = window.location.href.split('/')[3]

    const formOptions = {
        resolver: yupResolver(newUserValidationSchema),
    }

    const { register, handleSubmit, formState } = useForm(formOptions)
    const { errors } = formState

    useEffect(() => {
        ApiController.getInstance().user$.subscribe((user: any) => {
            if (user) {
                setUserDetails({
                    ...userDetail,
                    orgId: user.orgId
                })
            }
        });
    }, [])

    useEffect(() => {
        if (userId) {
            getUser(userId);
        }
    }, [userId]);

    const [error, setErrors] = useState({
        userName: '',
        email: '',
        phoneNumber: '',
        state: ''
    });

    const validateField = (name: any, value: any) => {
        const newErrors = { ...error };
        switch (name) {
            case 'userName':
                newErrors.userName = value ? '' : 'Name is required';
                break;
            case 'email':
                newErrors.email = isValidEmail(value) ? '' : 'Invalid email address';
                break;
            case 'phoneNumber':
                newErrors.phoneNumber = isValidPhoneNumber(value) ? '' : 'Invalid phone number';
                break;
            case 'state':
                newErrors.state = value ? '' : 'Invalid state selection';
                break;
            default:
                break;
        }
        setErrors(newErrors);
    };

    /**
     * 
     * @param e 
     */
    const onInputChange = (e: any) => {
        const { name, value } = e.target
        setUserDetails({
            ...userDetail,
            city: name === 'state' ? "" : ( name === 'city' ? value : userDetail.city),
            [name]: value,
        })
        validateField(name, value);
    }

    /**
     * 
     * @param e 
     * @returns 
     */
    async function onSubmit(e: any) {
        if (userDetail.userId) {
            updateUserProfile(userDetail)
        } else {
            addUserProfile(userDetail)
        }
        return false
    }

    const redirect = () => {
        navigate(`/${currentURL}/settings/user-management`)
    }


    useEffect(() => {
        if (userProfile && userProfile.userId && userId) {
            setUserDetails(userProfile)
        }
    }, [userProfile])

    return (
        <div className="pt-7 w-full h-full pr-4 pl-1 pb-7">
            <div
                style={{ background: '#F4F4F4' }}
                className="flex w-full h-full flex-initial  px-8 py-7 flex-col gap-2 rounded-2xl">
                <p className='hidden sm:block text-xs text-back-gray'><Link to={`/${currentURL}/settings`}>{t("Settings")}</Link> &gt;
                    <Link to={`/${currentURL}/settings/user-management`}>{t('UserManagement')}</Link> &gt;
                    {userDetail && userId ? t('EditUser') : t('AddUser')}</p>
                <h1 className="sm:text-3xl text-lg font-semibold text-highlight-blue pb-3">{t('Settings')}</h1>
                <div className="bg-white w-full flex flex-col rounded-xl gap-4">
                    <Link to={`/${currentURL}/settings`} className="py-3 border-b border-F5F5F5 text-xs pl-7">{t('Back')}</Link>
                    <div className="flex flex-row pb-4 gap-3 pl-7 border-b border-F5F5F5">
                        <i className="ph-bold ph-user-circle-plus sm:p-3 p-2 border border-F5F5F5 rounded-full"></i>
                        <div className="flex flex-col sm:gap-2 gap-1">
                            <p className="sm:text-sm text-xs">{userId && userDetail?.userName}</p>
                            <p className="sm:text-xs text-[10px] text-back-gray">{userId && userDetail?.email}</p>
                            {!userDetail?.userId && <p className="text-sm">{t('AddUser')}</p>}
                        </div>
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)} >
                        <div className="flex flex-col gap-2 px-7 sm:w-1/2">
                            <label htmlFor="userName" className="text-xs">{t('Name')}</label>
                            <input {...register('userName')} onChange={onInputChange} value={userDetail?.userName} required aria-label={t('Name')} type="text" name='userName' id='userName' className='rounded-xl border p-2 text-xs border-border-grey border-solid' />
                            <span className="text-xs text-highlight-red">{error?.userName}</span>
                            <span className="text-xs text-highlight-red">{errors?.userName?.message}</span>

                            <label htmlFor="email" className="text-xs">{t('email')}</label>
                            <input {...register('email')} disabled={userDetail?.userId} onChange={onInputChange} value={userDetail?.email} required aria-label={t('email')} type="email" name='email' id='email' className={`rounded-xl border p-2 text-xs border-border-grey border-solid ${userDetail?.userId ? 'disabled: bg-disable-grey' : ''} `} />
                            <span className="text-xs text-highlight-red">{error?.email}</span>
                            <span className="text-xs text-highlight-red">{errors?.email?.message}</span>

                            <label htmlFor="phoneNumber" className="text-xs">{t('MobileNumber')}</label>
                            <input {...register('phoneNumber')} onChange={onInputChange} value={userDetail?.phoneNumber} required aria-label={t('MobileNumber')} type="text" name='phoneNumber' id='phoneNumber' className={`rounded-xl border p-2 text-xs border-border-grey border-solid ${userDetail?.userId ? 'disabled: bg-disable-grey' : ''}`} />
                            <span className="text-xs text-highlight-red">{error?.phoneNumber}</span>
                            <span className="text-xs text-highlight-red">{errors?.phoneNumber?.message}</span>

                            <label htmlFor="PAN" className="text-xs">{t('PANNumber')}</label>
                            <input {...register('panNumber')} onChange={onInputChange} value={userDetail?.panNumber} aria-label={t('PANNumber')} type="text" name='panNumber' id='panNumber' className={`rounded-xl border p-2 text-xs border-solid ${errors?.panNumber?.message ? 'border-highlight-red' : 'border-border-grey'}`} />
                            <span className="text-xs text-highlight-red">{errors?.panNumber?.message}</span>

                            <label htmlFor="Address" className="text-xs">{t('Address')}</label>
                            <input  {...register('address')} onChange={onInputChange} value={userDetail?.address} aria-label={t('Address')} type="text" name='address' id='address' className={`rounded-xl border p-2 text-xs border-solid ${errors?.address?.message ? 'border-highlight-red' : 'border-border-grey'}`} />
                            <span className="text-xs text-highlight-red">{errors?.address?.message}</span>

                            <label htmlFor="State" className="text-xs">{t('State')}</label>
                            <select
                                aria-label={t('SelectState')} name='state' id='state' onChange={onInputChange}
                                className="rounded-lg border p-3 border-border-grey border-solid text-xs" value={userDetail?.state}
                            >
                                <option value="" disabled>{t('SelectState')}</option>
                                {StateType?.map((state: string) => {
                                    return <option key={state} value={state}>{state}</option>
                                })}
                            </select>
                            <span className="text-xs text-highlight-red">{error?.state}</span>

                            <div className="flex sm:flex-row flex-col sm:justify-between">
                                <div className="sm:w-1/2 flex flex-col gap-2 sm:pr-4">
                                    <label htmlFor="City" className="text-xs">{t('City')}</label>
                                    <select
                                        aria-label={t('SelectCity')} {...register('city')} name='city' id='city' onChange={onInputChange}
                                        className="rounded-lg border p-3 border-border-grey border-solid text-xs" value={userDetail?.city}
                                    >
                                        <option value="" disabled>{t('SelectCity')}</option>
                                        {CityType.filter((city: any) => city.state === userDetail?.state)?.map((city: any) => {
                                            return <option key={city} value={city.city}>{city.city}</option>
                                        })}
                                    </select>
                                    <span className="text-xs text-highlight-red">{errors?.city?.message}</span>

                                </div>
                                <div className="sm:w-1/2 flex flex-col gap-2">
                                    <label htmlFor="Pincode" className="text-xs">{t('Pincode')}</label>
                                    <input {...register('pinCode')} onChange={onInputChange} value={userDetail?.pinCode} aria-label={t('Pincode')} type="text" name='pinCode' id='pinCode' className={`rounded-lg text-xs border h-full p-2 border-solid ${errors?.pinCode?.message ? 'border-highlight-red' : 'border-border-grey'}`} />
                                    <span className="text-xs text-highlight-red">{errors?.pinCode?.message}</span>

                                </div>
                            </div>
                        </div>
                        <div className="flex flex-row gap-3 pl-7 pt-2">
                            <button disabled={error?.phoneNumber !== '' && error?.email !== ''} className="hover:bg-highlight-blue/[0.4] bg-highlight-blue items-center text-white flex flex-row gap-2 text-xs px-12 py-2.5 rounded-lg disabled:bg-highlight-blue/[0.4]" aria-label={t('Save')}>
                                {userDetail && userDetail.userId ? t('EditUser') : t('AddUser')}
                            </button>
                            <button onClick={redirect} aria-label={t('cancel')} className=" hover:bg-black/[0.4] text-xs  text-back-gray px-8 py-2.5 rounded-lg">
                                {t('cancel')}
                            </button>

                        </div>
                    </form>
                    <p className="pb-3 text-xs pl-7">{" "}</p>
                </div>

            </div>
        </div>
    )
}

export default UserSettings