import { useTranslation } from 'react-i18next';
import InfoTooltip from '../../../helpers/Tooltip';
import { Fragment, useRef, useState } from 'react';
import { saveAs } from 'file-saver'
import { CategoryMaster, ListComplainParams } from '../../../swagger/models';
import { COMPLAIN_STATUS, dateHelper, titleCase } from '../../common-module/helper';
import moment from "moment";

function Reports() {

  const { t } = useTranslation();
  const [selectedReport, setSelectedReport] = useState<string>("")
  const [selectedProcess, setSelectedProcess] = useState<string>("")
  const [categoryName, setCategoryName] = useState<string>("")
  const [selectedCategories, setSelectedCategories] = useState<CategoryMaster[]>([])
  const [selectedStatus, setSelectedStatus] = useState<string[]>([])
  const [filter, setFilter] = useState('')
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<any>(null);
  const [complainParams, setComplaintParams] = useState<ListComplainParams>()

  /**
  * Function to download the excel file of the selected status and category filters
  */
  const downloadOpeningReport = () => {
    fetch(`${import.meta.env.VITE_BASE_PATH}/admin/reports/opening_report`, {
      method: 'POST',
      headers: {
        Authorization: localStorage.getItem('TOKEN') || '',
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        selectedProcess: selectedProcess,
        status: [JSON.stringify(selectedStatus?.map((i: any) => i.status))],
        category: [JSON.stringify(selectedCategories?.map((i: CategoryMaster) => i.categoryId))],
        fromDate: complainParams?.fromDate,
        toDate: complainParams?.toDate
      })
    })
      .then((response) => {
        return response.blob()
      })
      .then((blob) => {
        saveAs(blob, 'Opening-Report.csv', { autoBom: false })
      })
      .catch((error) => {
        console.log(error)
      })
  }

  /**
 * Function to download the excel file of the selected status and category filters
 */
  const downloadReceivedReport = () => {
    fetch(`${import.meta.env.VITE_BASE_PATH}/admin/reports/received_report`, {
      method: 'POST',
      headers: {
        Authorization: localStorage.getItem('TOKEN') || '',
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        selectedProcess: selectedProcess,
        status: [JSON.stringify(selectedStatus?.map((i: any) => i.status))],
        category: [JSON.stringify(selectedCategories?.map((i: CategoryMaster) => i.categoryId))],
        fromDate: complainParams?.fromDate,
        toDate: complainParams?.toDate
      })
    })
      .then((response) => {
        return response.blob()
      })
      .then((blob) => {
        saveAs(blob, 'Received-Report.csv', { autoBom: false })
      })
      .catch((error) => {
        console.log(error)
      })
  }


  /**
 * Function to download the excel file of the selected status and category filters
 */
  const downloadAwardReceivedReport = () => {
    fetch(`${import.meta.env.VITE_BASE_PATH}/admin/reports/award_received_report`, {
      method: 'POST',
      headers: {
        Authorization: localStorage.getItem('TOKEN') || '',
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        selectedProcess: selectedProcess,
        status: [JSON.stringify(selectedStatus?.map((i: any) => i.status))],
        category: [JSON.stringify(selectedCategories?.map((i: CategoryMaster) => i.categoryId))],
        fromDate: complainParams?.fromDate,
        toDate: complainParams?.toDate
      })
    })
      .then((response) => {
        return response.blob()
      })
      .then((blob) => {
        saveAs(blob, 'Award-Received-Report.csv', { autoBom: false })
      })
      .catch((error) => {
        console.log(error)
      })
  }

  /**
* Function to download the excel file of pending arbitration report
*/
  const downloadPedningArbitrationReport = () => {
    fetch(`${import.meta.env.VITE_BASE_PATH}/admin/reports/pending_arbitration_report`, {
      method: 'POST',
      headers: {
        Authorization: localStorage.getItem('TOKEN') || '',
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        selectedProcess: selectedProcess,
        status: [JSON.stringify(selectedStatus?.map((i: any) => i.status))],
        category: [JSON.stringify(selectedCategories?.map((i: CategoryMaster) => i.categoryId))],
        fromDate: complainParams?.fromDate,
        toDate: complainParams?.toDate
      })
    })
      .then((response) => {
        return response.blob()
      })
      .then((blob) => {
        saveAs(blob, 'Pending-Arbitration-Report.csv', { autoBom: false })
      })
      .catch((error) => {
        console.log(error)
      })
  }

  /**
 * Function to download the excel file of the selected status and category filters
 */
  const downloadAggregateReport = () => {
    fetch(`${import.meta.env.VITE_BASE_PATH}/admin/reports/aggregate_report`, {
      method: 'POST',
      headers: {
        Authorization: localStorage.getItem('TOKEN') || '',
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        selectedProcess: selectedProcess,
        status: [JSON.stringify(selectedStatus?.map((i: any) => i.status))],
        category: [JSON.stringify(selectedCategories?.map((i: CategoryMaster) => i.categoryId))],
        fromDate: complainParams?.fromDate,
        toDate: complainParams?.toDate
      })
    })
      .then((response) => {
        return response.blob()
      })
      .then((blob) => {
        saveAs(blob, 'Aggregate-Report.csv', { autoBom: false })
      })
      .catch((error) => {
        console.log(error)
      })
  }

  /**
* Function to download the excel file of pending arbitration report
*/
  const downloadFifthReport = () => {
    fetch(`${import.meta.env.VITE_BASE_PATH}/admin/reports/fifth_report`, {
      method: 'POST',
      headers: {
        Authorization: localStorage.getItem('TOKEN') || '',
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        selectedProcess: selectedProcess,
        status: [JSON.stringify(selectedStatus?.map((i: any) => i.status))],
        category: [JSON.stringify(selectedCategories?.map((i: CategoryMaster) => i.categoryId))],
        fromDate: complainParams?.fromDate,
        toDate: complainParams?.toDate
      })
    })
      .then((response) => {
        return response.blob()
      })
      .then((blob) => {
        saveAs(blob, 'Fifth-Report.csv', { autoBom: false })
      })
      .catch((error) => {
        console.log(error)
      })
  }

  /**
* Function to download the excel file of pending arbitration report
*/
  const downloadCloseDataTMReport = () => {
    fetch(`${import.meta.env.VITE_BASE_PATH}/admin/reports/closed_data_against_tm`, {
      method: 'POST',
      headers: {
        Authorization: localStorage.getItem('TOKEN') || '',
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        selectedProcess: selectedProcess,
        status: [JSON.stringify(selectedStatus?.map((i: any) => i.status))],
        category: [JSON.stringify(selectedCategories?.map((i: CategoryMaster) => i.categoryId))],
        fromDate: complainParams?.fromDate,
        toDate: complainParams?.toDate
      })
    })
      .then((response) => {
        return response.blob()
      })
      .then((blob) => {
        saveAs(blob, 'Closed data for complaints against TM.csv', { autoBom: false })
      })
      .catch((error) => {
        console.log(error)
      })
  }

  /**
* Function to download the excel file of pending arbitration report
*/
  const downloadPendingDataTMReport = () => {
    fetch(`${import.meta.env.VITE_BASE_PATH}/admin/reports/pending_data_against_tm`, {
      method: 'POST',
      headers: {
        Authorization: localStorage.getItem('TOKEN') || '',
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        selectedProcess: selectedProcess,
        status: [JSON.stringify(selectedStatus?.map((i: any) => i.status))],
        category: [JSON.stringify(selectedCategories?.map((i: CategoryMaster) => i.categoryId))],
        fromDate: complainParams?.fromDate,
        toDate: complainParams?.toDate
      })
    })
      .then((response) => {
        return response.blob()
      })
      .then((blob) => {
        saveAs(blob, 'Closed data for complaints against TM.csv', { autoBom: false })
      })
      .catch((error) => {
        console.log(error)
      })
  }

  /**
* Function to download the excel file of pending arbitration report
*/
  const downloadAnnexure2Report = () => {
    fetch(`${import.meta.env.VITE_BASE_PATH}/admin/reports/annexture_report_2`, {
      method: 'POST',
      headers: {
        Authorization: localStorage.getItem('TOKEN') || '',
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        status: [JSON.stringify(selectedStatus?.map((i: any) => i.status))],
        fromDate: complainParams?.fromDate,
        toDate: complainParams?.toDate
      })
    })
      .then((response) => {
        return response.blob()
      })
      .then((blob) => {
        saveAs(blob, 'Annexure 2.xlsx', { autoBom: false })
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const downloadAnnexure10Report = () => {
    fetch(`${import.meta.env.VITE_BASE_PATH}/admin/reports/annexure_report_X`, {
      method: 'POST',
      headers: {
        Authorization: localStorage.getItem('TOKEN') || '',
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        status: [JSON.stringify(selectedStatus?.map((i: any) => i.status))],
        fromDate: complainParams?.fromDate,
        toDate: complainParams?.toDate
      })
    })
      .then((response) => {
        return response.blob()
      })
      .then((blob) => {
        saveAs(blob, 'Annexure 10.xlsx', { autoBom: false })
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const downloadActiveInactiveMPReport = () => {
    fetch(`${import.meta.env.VITE_BASE_PATH}/admin/reports/active_inactive_mp_excel`, {
      method: 'POST',
      headers: {
        Authorization: localStorage.getItem('TOKEN') || '',
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        status: [JSON.stringify(selectedStatus?.map((i: any) => i.status))],
        fromDate: complainParams?.fromDate,
        toDate: complainParams?.toDate
      })
    })
      .then((response) => {
        return response.blob()
      })
      .then((blob) => {
        saveAs(blob, 'Active-Inactive MP Data.xlsx', { autoBom: false })
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const downloadActiveInactiveIntermediaryReport = () => {
    fetch(`${import.meta.env.VITE_BASE_PATH}/admin/reports/active_inactive_intermediary_excel`, {
      method: 'POST',
      headers: {
        Authorization: localStorage.getItem('TOKEN') || '',
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        orgType: categoryName,
      })
    })
      .then((response) => {
        return response.blob()
      })
      .then((blob) => {
        saveAs(blob, 'Active-Inactive MP Data.xlsx', { autoBom: false })
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const downloadKnowYourBrokerReport = () => {
    fetch(`${import.meta.env.VITE_BASE_PATH}/admin/reports/know_your_broker`, {
      method: 'POST',
      headers: {
        Authorization: localStorage.getItem('TOKEN') || '',
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        selectedProcess: selectedProcess,
      })
    })
      .then((response) => {
        return response.blob()
      })
      .then((blob) => {
        saveAs(blob, 'Know your broker.xlsx', { autoBom: false })
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const generateReport = () => {
    switch (selectedReport) {
      case 'OpeningReport':
        downloadOpeningReport()
        break;
      case 'ReceivedReport':
        downloadReceivedReport()
        break;
      case 'AwardReceivedReport':
        downloadAwardReceivedReport()
        break;
      case 'AggregateReport':
        downloadAggregateReport()
        break;
      case 'PendingArbitrationReport':
        downloadPedningArbitrationReport()
        break;
      case 'FifthReport':
        downloadFifthReport()
        break;
      case 'CloseDataAgainstTM':
        downloadCloseDataTMReport()
        break;
      case 'PendingDataAgainstTM':
        downloadPendingDataTMReport()
        break;
      case 'Annexure2':
        downloadAnnexure2Report()
        break;
      case 'Annexure10':
        downloadAnnexure10Report()
        break;
      case 'ActiveInactiveMP':
        downloadActiveInactiveMPReport()
        break;
      case 'ActiveInactiveIntermediary':
        downloadActiveInactiveIntermediaryReport()
          break;
      case 'KnowYourBroker':
        downloadKnowYourBrokerReport()
          break;
      default:
        break;
    }
  }

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  /**
   * 
   * @param status 
   */
  const addStatusFilter = (status: any) => {
    const index = selectedStatus?.findIndex((i: any) => i.status === status.status)
    if (index === -1) {
      setSelectedStatus([
        ...selectedStatus,
        status
      ])
    }
  }

  return (
    <div className="pt-7 w-full h-full pr-1 sm:pr-4 pl-1 pb-7">
      <div
        style={{ background: "#F5F5F5" }}
        className=" w-full h-full flex-initial  pr-3 rounded-2xl "
      >
        <div className="flex w-full h-full flex-initial overflow-y-scroll overflow-hidden  px-8 py-7 flex-col gap-2 rounded-2xl">
          <p className="hidden sm:block text-xs text-back-gray">{t('reports')}</p>
          <div className="w-full flex flex-row justify-between">
            <div className="w-full flex flex-col gap-4">
              <div className="flex flex-row gap-3 items-center text-highlight-blue">
                <h1 className="sm:text-3xl text-lg font-semibold ">
                  {t('SelectProcess')}
                </h1>
                {/* <InfoTooltip title={t('FinancialIntermediaries')} /> */}
              </div>
              <div className="flex flex-row gap-9 items-start">

                <div className="relative" ref={dropdownRef}>
                  <button
                    type="button"
                    onClick={toggleDropdown}
                    className="inline-flex ga[-2] items-center justify-center w-full px-4 py-2 text-sm font-medium text-label-grey bg-EBEBEB rounded-lg hover:bg-white"
                    id="dropdown-menu-button"
                    aria-expanded={isOpen}
                    aria-haspopup="true"
                  >
                    <i className="ph-bold ph-funnel-simple pr-3"></i>
                    <p>{t('Filters')}</p>
                  </button>
                  {isOpen && (
                    <div
                      className="origin-top-right absolute left-0 mt-2 w-fit rounded-lg bg-EBEBEB"
                      role="menu"
                      aria-orientation="vertical"
                      aria-labelledby="dropdown-menu-button"
                    >
                      <div className="py-1" role="none">
                        {filter === '' && (
                          <div className="flex flex-col gap-1 py-2 px-5 cursor-pointer">
                            <p className="text-xs items-center cursor-pointer py-2 rounded-lg hover:bg-white px-4" onClick={() => setFilter('Category')} >{t('Category')}</p>
                            <p className="text-xs items-center cursor-pointer py-2 rounded-lg hover:bg-white px-4" onClick={() => setFilter('DateRange')} >{t('DateRange')}</p>
                          </div>
                        )}
                        {filter === "Status" &&
                          (
                            <div className="flex flex-col gap-1 py-2 px-3 cursor-pointer">
                              <p className="text-xs text-back-gray cursor-pointer pb-2 px-3 hover:text-gray-700" onClick={() => setFilter('')} >{t('Back')}</p>
                              {COMPLAIN_STATUS?.map((status: any, index: number) => {
                                return (
                                  <div key={status.status} className="flex flex-row gap-2 py-2 px-3 text-xs rounded-lg cursor-pointer  hover:bg-white" onClick={() => addStatusFilter(status)}>
                                    <div className={`border relative w-7 rounded-full ${status.icon}`}>
                                      {status?.status === 'CONCILIATION_IN_PROGRESS' &&
                                        <div className="w-2/3 rounded-full h-full bg-status-green"></div>
                                      }
                                      {status?.status === 'ARBITRATION_IN_PROGRESS' &&
                                        <div className="w-2/3 rounded-full h-full bg-status-green"></div>
                                      }
                                    </div>
                                    <p className={`${status?.subIcon || ''} whitespace-nowrap`}>{titleCase(status.status)}</p>
                                  </div>
                                )
                              })}
                            </div>
                          )
                        }
                        {filter === "DateRange" &&
                          (
                            <div className="flex flex-col gap-1 py-2 px-3 cursor-pointer">
                              <p className="text-xs text-back-gray cursor-pointer pb-2 px-3 hover:text-gray-700" onClick={() => setFilter('')} >{t('Back')}</p>
                              <div className="flex sm:flex-row flex-col gap-2 sm:gap-0 justify-between items-baseline">
                                <div className="flex flex-row gap-2 items-center">
                                  <label htmlFor="Date" className="text-xs">{t('FromDate')}</label>
                                </div>
                                <input type="date" name="FromDate" id="Date" max={moment().format('YYYY-MM-DD')} min={"1970-01-01"} onChange={(e) => setComplaintParams({
                                  ...complainParams,
                                  fromDate: e.target.value
                                })} className="border rounded-lg border-back-gray w-full p-2" />
                              </div>
                              <div className="flex sm:flex-row flex-col gap-2 sm:gap-0 justify-between items-baseline">
                                <div className="flex flex-row gap-2 items-center">
                                  <label htmlFor="Date" className="text-xs">{t('toDate')}</label>
                                </div>
                                <input type="date" name="toDate" id="Date" max={moment().format('YYYY-MM-DD')} min={complainParams?.fromDate ? moment(complainParams?.fromDate).format('YYYY-MM-DD') : "1970-01-01"} onChange={(e) => setComplaintParams({
                                  ...complainParams,
                                  toDate: e.target.value
                                })} className="border rounded-lg border-back-gray w-full p-2" />
                              </div>
                            </div>
                          )
                        }
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div>
                {(selectedCategories.length !== 0 || selectedStatus.length !== 0 || complainParams?.fromDate || complainParams?.toDate) && (
                  <div className="grid grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-4">

                    {selectedCategories.length !== 0 && (
                      <>
                        {selectedCategories?.map((category: CategoryMaster) =>
                        (
                          <div key={category?.categoryId}>
                            <div className="text-xs bg-EBEBEB rounded-xl py-3 px-5 flex flex-row gap-3 items-center">
                              <p>{t('Category')}</p>
                              <div className="flex flex-row gap-2 text-xs bg-100thgrey py-1 rounded-lg px-2" >
                                <i className="ph-bold ph-buildings text-sm"></i>
                                <p>{category?.categoryName}</p>
                              </div>
                            </div>
                          </div>
                        )
                        )}
                      </>
                    )}
                    {selectedStatus.length > 0 && (
                      <>
                        {selectedStatus?.map((status: any) =>
                        (
                          <div key={status?.status}>
                            <div className="text-xs h-full bg-EBEBEB rounded-xl py-3 px-5 flex flex-row gap-3 items-center">
                              <p>{t('Status')}</p>
                              <div className="flex flex-row gap-2 text-xs bg-100thgrey py-1 rounded-lg px-2">
                                <div className={`border w-7 rounded-full ${status.icon}`}>
                                  {status?.status === 'CONCILIATION_IN_PROGRESS' &&
                                    <div className="w-2/3 rounded-full h-full bg-conc-green"></div>
                                  }
                                  {status?.status === 'ARBITRATION_IN_PROGRESS' &&
                                    <div className="w-2/3 rounded-full h-full bg-highlight-blue"></div>
                                  }
                                </div>
                                <p className={`${status?.subIcon || ''}`}>{titleCase(status.status)}</p>
                              </div>
                            </div>
                          </div>
                        )
                        )}
                      </>
                    )}
                    {complainParams?.fromDate && (
                      <div>
                        <div className="text-xs bg-EBEBEB rounded-xl py-3 px-5 flex flex-row gap-3 items-center">
                          <p>{t('FromDate')}</p>
                          <div className="flex flex-row gap-2 text-xs bg-100thgrey py-1 rounded-lg px-2" >
                            <i className="ph-bold ph-calendar text-sm"></i>
                            <p>{dateHelper(complainParams?.fromDate)}</p>
                          </div>
                        </div>
                      </div>
                    )}
                    {complainParams?.toDate && (
                      <div>
                        <div className="text-xs bg-EBEBEB rounded-xl py-3 px-5 flex flex-row gap-3 items-center">
                          <p>{t('toDate')}</p>
                          <div className="flex flex-row gap-2 text-xs bg-100thgrey py-1 rounded-lg px-2" >
                            <i className="ph-bold ph-calendar text-sm"></i>
                            <p>{dateHelper(complainParams?.toDate)}</p>
                          </div>
                        </div>
                      </div>
                    )}
                    <button
                      type="button"
                      className="inline-flex items-center justify-center w-fit px-4 py-2 text-sm font-medium text-label-grey bg-EBEBEB rounded-lg hover:bg-white"
                      id="dropdown-menu-button"
                      onClick={() => {
                        setSelectedCategories([])
                        setSelectedStatus([])
                        setFilter('')
                        setComplaintParams({
                          ...complainParams,
                          fromDate: undefined,
                          toDate: undefined
                        })
                      }}
                    >
                      <i className="ph-bold ph-x pr-2"></i>
                      <p className="text-xs">{t('Clear')}</p>

                    </button>
                  </div>
                )}
              </div>
              <div className="grid grid-cols-1 md:grid-cols-3 sm:grid-cols-2 lg:grid-cols-3 gap-9 sm:pr-36 w-full">

                {/* <Fragment>
                  <div className={`${selectedProcess === 'Pre-Conciliation' ? 'text-white bg-highlight-blue' : 'text-highlight-blue bg-light-blue'} flex flex-col py-12 px-6  hover:bg-highlight-blue rounded-xl cursor-pointer hover:text-white items-center gap-2`}
                    onClick={() => {
                      setSelectedProcess('Pre-Conciliation')
                    }}>
                    <i className={`ph-bold ph-buildings`}></i>
                    <p className="text-xs text-center">{t('Pre-Conciliation')}</p>
                  </div>
                </Fragment> */}
                {/* {false && */}
                  <Fragment>
                    <Fragment>
                      <div className={`${selectedProcess === 'Conciliation' ? 'text-white bg-highlight-blue' : 'text-highlight-blue bg-light-blue'} flex flex-col py-12 px-6  hover:bg-highlight-blue rounded-xl cursor-pointer hover:text-white items-center gap-2`}
                        onClick={() => {
                          setSelectedProcess('Conciliation')
                        }}>
                        <i className={`ph-bold ph-buildings`}></i>
                        <p className="text-xs text-center">{t('Conciliation')}</p>
                      </div>
                    </Fragment>
                    <Fragment>
                      <div className={`${selectedProcess === 'Arbitration' ? 'text-white bg-highlight-blue' : 'text-highlight-blue bg-light-blue'} flex flex-col py-12 px-6  hover:bg-highlight-blue rounded-xl cursor-pointer hover:text-white items-center gap-2`}
                        onClick={() => {
                          setSelectedProcess('Arbitration')
                        }}>
                        <i className={`ph-bold ph-buildings`}></i>
                        <p className="text-xs text-center">{t('Arbitration')}</p>
                      </div>
                    </Fragment>
                  </Fragment>
                 {/* } */}
                <Fragment>
                  <div className={`${selectedProcess === 'Other' ? 'text-white bg-highlight-blue' : 'text-highlight-blue bg-light-blue'} flex flex-col py-12 px-6  hover:bg-highlight-blue rounded-xl cursor-pointer hover:text-white items-center gap-2`}
                    onClick={() => {
                      setSelectedProcess('Other')
                    }}>
                    <i className={`ph-bold ph-buildings`}></i>
                    <p className="text-xs text-center">{t('Other')}</p>
                  </div>
                </Fragment>
              </div>
              {selectedProcess !== 'Other' && selectedProcess !== '' &&
                <Fragment>
                  <hr />
                  <h1 className="sm:text-3xl text-lg font-semibold text-highlight-blue">{t('SelectReportType')}</h1>
                  <div className="bg-white w-full flex flex-col rounded-xl">
                    <p className="border-b border-F5F5F5 text-xs pl-7"></p>
                    <div className={`${selectedReport === 'OpeningReport' ? 'text-white bg-highlight-blue' : ''} hover:bg-highlight-blue hover:text-white cursor-pointer flex flex-row py-4 items-center justify-between px-7 border-b border-F5F5F5`}>
                      <div className="flex flex-row gap-3 w-full"
                        onClick={() => {
                          setSelectedReport('OpeningReport')
                        }}>
                        <i className="ph-bold ph ph-files sm:p-3 p-2 border border-F5F5F5 rounded-full"></i>
                        <div className="flex flex-col sm:gap-2 gap-1">
                          <p className="sm:text-sm text-xs">{t('OpeningReport')}</p>
                          <p className="sm:text-xs text-[10px] text-back-gray">{t('DownloadOpeningReport')}</p>
                        </div>
                      </div>
                    </div>
                    <div className={`${selectedReport === 'ReceivedReport' ? 'text-white bg-highlight-blue' : ''} hover:bg-highlight-blue hover:text-white cursor-pointer flex flex-row py-4 items-center justify-between px-7 border-b border-F5F5F5`}>
                      <div className="flex flex-row gap-3 w-full" onClick={() => {
                        setSelectedReport('ReceivedReport')
                      }}>
                        <i className="ph-bold ph ph-files sm:p-3 p-2 border border-F5F5F5 rounded-full"></i>
                        <div className="flex flex-col sm:gap-2 gap-1">
                          <p className="sm:text-sm text-xs">{t('ReceivedReport')}</p>
                          <p className="sm:text-xs text-[10px] text-back-gray">{t('DownloadReceivedReport')}</p>
                        </div>
                      </div>
                    </div>
                    <div className={`${selectedReport === 'KnowYourBroker' ? 'text-white bg-highlight-blue' : ''} hover:bg-highlight-blue hover:text-white cursor-pointer flex flex-row py-4 items-center justify-between px-7 border-b border-F5F5F5`}>
                      <div className="flex flex-row gap-3 w-full" onClick={() => {
                        setSelectedReport('KnowYourBroker')
                      }}>
                        <i className="ph-bold ph ph-files sm:p-3 p-2 border border-F5F5F5 rounded-full"></i>
                        <div className="flex flex-col sm:gap-2 gap-1">
                          <p className="sm:text-sm text-xs">{t('KnowYourBroker')}</p>
                          <p className="sm:text-xs text-[10px] text-back-gray">{t('DownloadKnowYourBroker')}</p>
                        </div>
                      </div>
                    </div>
                    {selectedProcess === 'Arbitration' &&
                      <Fragment>
                        <div className={`${selectedReport === 'AwardReceivedReport' ? 'text-white bg-highlight-blue' : ''} hover:bg-highlight-blue hover:text-white cursor-pointer flex flex-row py-4 items-center justify-between px-7 border-b border-F5F5F5`}>
                          <div className="flex flex-row gap-3 w-full" onClick={() => {
                            setSelectedReport('AwardReceivedReport')
                          }}>
                            <i className="ph-bold ph ph-files sm:p-3 p-2 border border-F5F5F5 rounded-full"></i>
                            <div className="flex flex-col sm:gap-2 gap-1">
                              <p className="sm:text-sm text-xs">{t('AwardReceivedReport')}</p>
                              <p className="sm:text-xs text-[10px] text-back-gray">{t('DownloadAwardReceivedReport')}</p>
                            </div>
                          </div>
                        </div>
                        <div className={`${selectedReport === 'PendingArbitrationReport' ? 'text-white bg-highlight-blue' : ''} hover:bg-highlight-blue hover:text-white cursor-pointer flex flex-row py-4 items-center justify-between px-7 border-b border-F5F5F5`}>
                          <div className="flex flex-row gap-3 w-full" onClick={() => {
                            setSelectedReport('PendingArbitrationReport')
                          }}>
                            <i className="ph-bold ph ph-files sm:p-3 p-2 border border-F5F5F5 rounded-full"></i>
                            <div className="flex flex-col sm:gap-2 gap-1">
                              <p className="sm:text-sm text-xs">{t('PendingArbitrationReport')}</p>
                              <p className="sm:text-xs text-[10px] text-back-gray">{t('DownloadPendingArbitrationReport')}</p>
                            </div>
                          </div>
                        </div>
                        <div className={`${selectedReport === 'FifthReport' ? 'text-white bg-highlight-blue' : ''} hover:bg-highlight-blue hover:text-white cursor-pointer flex flex-row py-4 items-center justify-between px-7 border-b border-F5F5F5`}>
                          <div className="flex flex-row gap-3 w-full" onClick={() => {
                            setSelectedReport('FifthReport')
                          }}>
                            <i className="ph-bold ph ph-files sm:p-3 p-2 border border-F5F5F5 rounded-full"></i>
                            <div className="flex flex-col sm:gap-2 gap-1">
                              <p className="sm:text-sm text-xs">{t('FifthReport')}</p>
                              <p className="sm:text-xs text-[10px] text-back-gray">{t('DownloadFifthReport')}</p>
                            </div>
                          </div>
                        </div>
                        {/* <div className={`${selectedReport === 'AggregateReport' ? 'text-white bg-highlight-blue' : ''} hover:bg-highlight-blue hover:text-white cursor-pointer flex flex-row py-4 items-center justify-between px-7 border-b border-F5F5F5`}>
                          <div className="flex flex-row gap-3 w-full" onClick={() => {
                            setSelectedReport('AggregateReport')
                          }}>
                            <i className="ph-bold ph ph-files sm:p-3 p-2 border border-F5F5F5 rounded-full"></i>
                            <div className="flex flex-col sm:gap-2 gap-1">
                              <p className="sm:text-sm text-xs">{t('AggregateReport')}</p>
                              <p className="sm:text-xs text-[10px] text-back-gray">{t('DownloadAggregateReport')}</p>
                            </div>
                          </div>
                        </div> */}
                      </Fragment>
                    }

                    {selectedProcess === 'Conciliation' &&
                      <Fragment>
                        <div className={`${selectedReport === 'CloseDataAgainstTM' ? 'text-white bg-highlight-blue' : ''} hover:bg-highlight-blue hover:text-white cursor-pointer flex flex-row py-4 items-center justify-between px-7 border-b border-F5F5F5`}>
                          <div className="flex flex-row gap-3 w-full" onClick={() => {
                            setSelectedReport('CloseDataAgainstTM')
                          }}>
                            <i className="ph-bold ph ph-files sm:p-3 p-2 border border-F5F5F5 rounded-full"></i>
                            <div className="flex flex-col sm:gap-2 gap-1">
                              <p className="sm:text-sm text-xs">{t('CloseDataAgainstTM')}</p>
                              <p className="sm:text-xs text-[10px] text-back-gray">{t('DownloadCloseDataAgainstTM')}</p>
                            </div>
                          </div>
                        </div>
                        <div className={`${selectedReport === 'PendingDataAgainstTM' ? 'text-white bg-highlight-blue' : ''} hover:bg-highlight-blue hover:text-white cursor-pointer flex flex-row py-4 items-center justify-between px-7 border-b border-F5F5F5`}>
                          <div className="flex flex-row gap-3 w-full" onClick={() => {
                            setSelectedReport('PendingDataAgainstTM')
                          }}>
                            <i className="ph-bold ph ph-files sm:p-3 p-2 border border-F5F5F5 rounded-full"></i>
                            <div className="flex flex-col sm:gap-2 gap-1">
                              <p className="sm:text-sm text-xs">{t('PendingDataAgainstTM')}</p>
                              <p className="sm:text-xs text-[10px] text-back-gray">{t('DownloadPendingDataAgainstTM')}</p>
                            </div>
                          </div>
                        </div>
                      </Fragment>}
                  </div>
                  <div className="flex flex-row gap-3 justify-end">
                    <button onClick={() => { setSelectedProcess(""); setSelectedReport("") }} aria-label={t('cancel')} className=" hover:bg-black/[0.4] text-xs  text-back-gray px-8 py-2 rounded-lg">
                      {t('cancel')}
                    </button>
                    <button className="hover:bg-highlight-blue/[0.4] bg-highlight-blue items-center text-white flex flex-row gap-2 text-xs px-12 py-2 rounded-lg disabled:bg-highlight-blue/[0.4]" aria-label={t('Proceed')}
                      onClick={(e) => { generateReport() }}
                    >
                      <p>{t('Proceed')}</p>
                    </button>
                  </div>
                </Fragment>
              }
              {selectedProcess === 'Other' &&
                <Fragment>
                  <hr />
                  <h1 className="sm:text-3xl text-lg font-semibold text-highlight-blue">{t('SelectReportType')}</h1>
                  <div className="bg-white w-full flex flex-col rounded-xl">
                    <p className="border-b border-F5F5F5 text-xs pl-7"></p>
                    <div className={`${selectedReport === 'Annexure2' ? 'text-white bg-highlight-blue' : ''} hover:bg-highlight-blue hover:text-white cursor-pointer flex flex-row py-4 items-center justify-between px-7 border-b border-F5F5F5`}>
                      <div className="flex flex-row gap-3 w-full"
                        onClick={() => {
                          setSelectedReport('Annexure2')
                        }}>
                        <i className="ph-bold ph ph-files sm:p-3 p-2 border border-F5F5F5 rounded-full"></i>
                        <div className="flex flex-col sm:gap-2 gap-1">
                          <p className="sm:text-sm text-xs">{t('Annexure2')}</p>
                          <p className="sm:text-xs text-[10px] text-back-gray">{t('Annexure2')}</p>
                        </div>
                      </div>
                    </div>
                    {/* <div className={`${selectedReport === 'Annexure10' ? 'text-white bg-highlight-blue' : ''} hover:bg-highlight-blue hover:text-white cursor-pointer flex flex-row py-4 items-center justify-between px-7 border-b border-F5F5F5`}>
                      <div className="flex flex-row gap-3 w-full" onClick={() => {
                        setSelectedReport('Annexure10')
                      }}>
                        <i className="ph-bold ph ph-files sm:p-3 p-2 border border-F5F5F5 rounded-full"></i>
                        <div className="flex flex-col sm:gap-2 gap-1">
                          <p className="sm:text-sm text-xs">{t('Annexure10')}</p>
                          <p className="sm:text-xs text-[10px] text-back-gray">{t('Annexure10')}</p>
                        </div>
                      </div>
                    </div> */}
                    {/* <div className={`${selectedReport === 'ActiveInactiveMP' ? 'text-white bg-highlight-blue' : ''} hover:bg-highlight-blue hover:text-white cursor-pointer flex flex-row py-4 items-center justify-between px-7 border-b border-F5F5F5`}>
                      <div className="flex flex-row gap-3 w-full"
                        onClick={() => {
                          setSelectedReport('ActiveInactiveMP')
                        }}>
                        <i className="ph-bold ph ph-files sm:p-3 p-2 border border-F5F5F5 rounded-full"></i>
                        <div className="flex flex-col sm:gap-2 gap-1">
                          <p className="sm:text-sm text-xs">{t('ActiveInactiveMP')}</p>
                          <p className="sm:text-xs text-[10px] text-back-gray">{t('ActiveInactiveMP')}</p>
                        </div>
                      </div>
                    </div> */}
                    <div className={`${selectedReport === 'ActiveInactiveIntermediary' && categoryName === 'SB' ? 'text-white bg-highlight-blue' : ''} hover:bg-highlight-blue hover:text-white cursor-pointer flex flex-row py-4 items-center justify-between px-7 border-b border-F5F5F5`}>
                      <div className="flex flex-row gap-3 w-full"
                        onClick={() => {
                          setSelectedReport('ActiveInactiveIntermediary');
                          setCategoryName('SB')
                        }}>
                        <i className="ph-bold ph ph-files sm:p-3 p-2 border border-F5F5F5 rounded-full"></i>
                        <div className="flex flex-col sm:gap-2 gap-1">
                          <p className="sm:text-sm text-xs">{t('StockBroker')}</p>
                          <p className="sm:text-xs text-[10px] text-back-gray">{t('StockBroker')}</p>
                        </div>
                      </div>
                    </div>
                    <div className={`${selectedReport === 'ActiveInactiveIntermediary' && categoryName === 'DP'  ? 'text-white bg-highlight-blue' : ''} hover:bg-highlight-blue hover:text-white cursor-pointer flex flex-row py-4 items-center justify-between px-7 border-b border-F5F5F5`}>
                      <div className="flex flex-row gap-3 w-full"
                        onClick={() => {
                          setSelectedReport('ActiveInactiveIntermediary');
                          setCategoryName('DP')
                        }}>
                        <i className="ph-bold ph ph-files sm:p-3 p-2 border border-F5F5F5 rounded-full"></i>
                        <div className="flex flex-col sm:gap-2 gap-1">
                          <p className="sm:text-sm text-xs">{t('DepositoryParticipant')}</p>
                          <p className="sm:text-xs text-[10px] text-back-gray">{t('DepositoryParticipant')}</p>
                        </div>
                      </div>
                    </div>
                    <div className={`${selectedReport === 'ActiveInactiveIntermediary' && categoryName === 'LC'  ? 'text-white bg-highlight-blue' : ''} hover:bg-highlight-blue hover:text-white cursor-pointer flex flex-row py-4 items-center justify-between px-7 border-b border-F5F5F5`}>
                      <div className="flex flex-row gap-3 w-full"
                        onClick={() => {
                          setSelectedReport('ActiveInactiveIntermediary');
                          setCategoryName('LC')
                        }}>
                        <i className="ph-bold ph ph-files sm:p-3 p-2 border border-F5F5F5 rounded-full"></i>
                        <div className="flex flex-col sm:gap-2 gap-1">
                          <p className="sm:text-sm text-xs">{t('ListedCompany')}</p>
                          <p className="sm:text-xs text-[10px] text-back-gray">{t('ListedCompany')}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row gap-3 justify-end">
                    <button aria-label={t('cancel')} className=" hover:bg-black/[0.4] text-xs  text-back-gray px-8 py-2 rounded-lg">
                      {t('cancel')}
                    </button>
                    <button className="hover:bg-highlight-blue/[0.4] bg-highlight-blue items-center text-white flex flex-row gap-2 text-xs px-12 py-2 rounded-lg disabled:bg-highlight-blue/[0.4]" aria-label={t('Proceed')}
                      onClick={(e) => { generateReport() }}
                    >
                      <p>{t('Proceed')}</p>
                    </button>
                  </div>
                </Fragment>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Reports