import { toast } from "react-hot-toast";
import { ApiController } from "../apiController";
import { loader, notifyError, notifySuccess } from "../helpers/helper";
import {
  ComplainComment,
  ComplainUpdate,
  ComplainNature,
  Complain,
  CategoryMaster,
  ArbitrationReport,
  DisputeArbitrator,
  ConciliationReport,
} from "../swagger/models";
import { ListComplainParams } from "../swagger/models/list-complain-params";
import { titleCase } from "../Components/common-module/helper";
import { Orchestration } from "framer-motion";

export const ComplainStore = (set: any, get: any, store: any) => ({
  complaints: [],
  complaint: {},
  complainFiled: false,
  files: [],
  allfiles: [],
  natureFiles: [],
  arbitralFiles: [],
  conciliatorFiles: [],
  updateFiles: [],
  comment: {},
  updates: [],
  selectedArbitrators: [],
  complaintStatus: "",
  errors: true,
  redirectionURL: "",
  arbitralAwardDetails: {},
  conciliatorAwardDetails: {},
  complainCount: 0,
  updatedDisputeNatureType: {},
  complainFilters: {
    selectedCategories: [],
    selectedStatus: [],
    selectedMII: [],
    page: 1,
    limit: 10,
    fromDate: undefined,
    toDate: undefined,
  },
  /**
   * Function to set the complaint filters
   * @param selectedCategories
   * @param selectedStatus
   * @param page
   * @param limit
   * @param fromDate
   * @param toDate
   */
  setComplainFilters: (
    selectedCategories: CategoryMaster[],
    selectedStatus: string[],
    selectedMII: Orchestration[],
    page: number,
    limit: number,
    fromDate: string,
    toDate: string
  ) => {
    set({
      complainFilters: {
        selectedCategories,
        selectedStatus,
        selectedMII,
        page,
        limit,
        fromDate,
        toDate,
      },
    });
  },
  /**
   *
   * @param complaintUUID
   */
  getComplaint: async (complaintUUID: string) => {
    const toastId = loader("Loading Complaint...");
    set({
      complaint: {},
      complaintStatus: null,
    });
    try {
      const complaintData =
        await ApiController.getInstance().complainApi.complaint(complaintUUID);
      set({
        complaint: complaintData.data,
        complaintStatus: complaintData.data.status,
        selectedArbitrators: [],
      });
      toast.dismiss(toastId);
    } catch (error: any) {
      toast.dismiss(toastId);
      set({
        complaint: {},
        complaintStatus: null,
      });
      notifyError(error?.response?.data?.message);
    }
  },
  /**
   * @param complainParams
   */
  listComplaints: async (complainParams: ListComplainParams) => {
    const toastId = loader("Loading Disputes...");
    try {
      const complaintData =
        await ApiController.getInstance().complainApi.listComplaints(
          complainParams
        );
      set({
        complaints: complaintData.data.Complaints,
        complainCount: complaintData.data.complaintsCount,
      });
      toast.dismiss(toastId);
    } catch (error: any) {
      console.log(error);
      toast.dismiss(toastId);
      notifyError(error?.response?.data?.message);
    }
  },

  fileComplaints: async (complainObject: any) => {
    const toastId = loader("Filing Dispute...");
    try {
      const complaintData =
        await ApiController.getInstance().complainApi.fileComplaint(
          complainObject
        );
      set({
        complainFiled: true,
        complaint: complaintData.data,
        natureFiles: [],
      });
      ApiController.getInstance().updateComplainFileStatus(complaintData.data);
      toast.dismiss(toastId);
    } catch (error: any) {
      toast.dismiss(toastId);
      notifyError(error?.response?.data?.message);
      error?.response?.data?.map((err: any, i: any) => {
        notifyError(err);
      });
    }
  },

  fileComplaintAgainstIntermediary: async (complainObject: any) => {
    const toastId = loader("Filing Dispute...");
    try {
      const complaintData =
        await ApiController.getInstance().complainApi.fileComplaintIntermediary(
          complainObject
        );
      set({
        complainFiled: true,
        complaint: complaintData.data,
        natureFiles: [],
      });
      ApiController.getInstance().updateComplainFileStatus(complaintData.data);
      toast.dismiss(toastId);
    } catch (error: any) {
      toast.dismiss(toastId);
      notifyError(error?.response?.data?.message);
    }
  },

  fileComplaintAgainstInvestor: async (complainObject: any) => {
    const toastId = loader("Filing Dispute...");
    try {
      const complaintData =
        await ApiController.getInstance().complainApi.fileComplaintInvestor(
          complainObject
        );
      set({
        complainFiled: true,
        complaint: complaintData.data,
        natureFiles: [],
      });
      ApiController.getInstance().updateComplainFileStatus(complaintData.data);
      toast.dismiss(toastId);
    } catch (error: any) {
      toast.dismiss(toastId);
      notifyError(error?.response?.data?.message);
    }
  },

  fetchCaseUpdates: async (complainId: any) => {
    const toastId = loader("Loading Updates...");
    set({ updates: [] });
    try {
      const updates = await ApiController.getInstance().complainApi.allUpdates(
        complainId
      );
      set({ updates: updates.data });
      toast.dismiss(toastId);
    } catch (error: any) {
      set({ updates: [] });
      toast.dismiss(toastId);
      notifyError(error?.response?.data?.message);
    }
  },

  downloadFiles: async (complainId: any) => {
    try {
      const allfiles = await ApiController.getInstance().complainApi.allFiles(
        complainId
      );
      set({ allfiles: allfiles.data });
      return allfiles.data;
    } catch (error: any) {
      notifyError(error?.response?.data?.message);
    }
  },

  updateStatusofComplain: async (statusObject: any, error: boolean) => {
    const toastId = loader("Updating Status...");
    try {
      const status = await ApiController.getInstance().complainApi.updateStatus(
        statusObject
      );

      set((state: any) => ({
        errors: false,
        complaint: {
          ...state.complaint,
          status: statusObject.status,
        },
        complaintStatus: statusObject.status,
      }));

      toast.dismiss(toastId);
      if (statusObject.status === "ARBITRATION_INITIATED") {
        notifySuccess("You have successfully initiated Arbitration");
      } else if (statusObject.status === "CONCILIATION_INITIATED") {
        notifySuccess("You have successfully initiated Conciliation");
      } else {
        notifySuccess(
          "You have successfully changed the status to " +
          titleCase(statusObject.status)
        );
      }

      return status;
    } catch (error: any) {
      set({ errors: true });
      toast.dismiss(toastId);
      notifyError(error?.response?.data?.message);
    }
  },

  createUpdate: async (updatePayload: ComplainUpdate) => {
    const toastId = loader("Creating Update...");
    try {
      const updateDetail =
        await ApiController.getInstance().complainApi.createUpdate(
          updatePayload
        );
      set((state: any) => ({
        updates: [...state.updates, updateDetail.data],
        updateFiles: [],
      }));
      toast.dismiss(toastId);
    } catch (error: any) {
      toast.dismiss(toastId);
      notifyError(error?.response?.data?.message);
    }
  },

  deleteUpdateFiles: async (updateFile: any) => {
    set((state: any) => ({
      updateFiles: state.updateFiles.filter(
        (file: any) => file.fileName !== updateFile
      ),
    }));
  },

  uploadFile: async (
    file: any,
    natureType: any,
    nature: any,
    subCategoryId: number
  ) => {
    const toastId = loader("Uploading File...");
    try {
      const complaintFile =
        await ApiController.getInstance().complainApi.uploadFile(
          file,
          subCategoryId
        );

      const files = get().natureFiles;
      set({
        natureFiles: [
          ...files,
          {
            file: complaintFile.data.url,
            fileName: complaintFile.data.fileName,
            fileType: complaintFile.data.contentType,
            uploadedBy: complaintFile.data.uploadedBy,
            timestamp: complaintFile.data.timestamp,
            uploadedAt: new Date(),
            natureType,
            nature,
          },
        ],
      });
      toast.dismiss(toastId);
      notifySuccess("File Upload Successfully");
      return complaintFile;
    } catch (e: any) {
      toast.dismiss(toastId);
      notifyError(e?.response?.data?.message);
    }
  },

  transferDisputeToODR: async (complaintUUID: any) => {
    const toastId = loader("Transferring File...");
    try {
      const transferResponse =
        await ApiController.getInstance().complainApi.toOdr(complaintUUID);

      if (transferResponse.data && transferResponse.data.success) {
        toast.dismiss(toastId);
        notifySuccess("Dispute Transfer Successful");
      } else {
        toast.dismiss(toastId);
        notifyError("Dispute transfer Error");
      }
    } catch (e: any) {
      toast.dismiss(toastId);
      notifyError(e?.response?.data?.message);
    }
  },

  allMiis: async (complaintUUID: any, miiId: any) => {
    const toastId = loader("Fetching MIIs...");

    try {
      const response = await ApiController.getInstance().complainApi.miis({
        miiId: miiId,
        complainId: complaintUUID,
      });
      toast.dismiss(toastId);
      return response.data;
    } catch (e: any) {
      toast.dismiss(toastId);
      notifyError("Some error occured");
    }
  },

  transferMIIs: async (complainId: any, miiId: any) => {
    const toastId = loader("Assigning..");
    try {
      const response = await ApiController.getInstance().complainApi.transfer({
        complainId: complainId,
        miiId: miiId,
      });

      toast.dismiss(toastId);
      notifySuccess("MII Reassigned Successfully");
      return response.data;
    } catch (error: any) {
      toast.dismiss(toastId);
      notifyError(error?.response?.data?.message);
    }
  },

  downloadFile: async (
    key: string,
    complainId: number,
    complainNatureId?: number,
    complainUpdateId?: number,
    conciliationReportId?: number,
    arbitrationReportId?: number,
    fileName?: string
  ) => {
    const toastId = loader("Downloading File...");
    try {
      const downloadUrl =
        await ApiController.getInstance().complainApi.downloadFile({
          url: key,
          complainId: complainId,
          complainNatureId: complainNatureId,
          complainUpdateId: complainUpdateId,
          arbitrationReportId: arbitrationReportId,
          conciliationReportId: conciliationReportId,
          fileName: fileName,
        });
      toast.dismiss(toastId);
      return downloadUrl.data;
    } catch (e: any) {
      toast.dismiss(toastId);
      notifyError("File Generation Error");
    }
  },

  uploadCommentFile: async (file: any, complainId: number) => {
    const toastId = loader("Uploading File...");
    try {
      const complaintFile =
        await ApiController.getInstance().complainApi.uploadFile(
          file,
          complainId
        );

      const files = get().updateFiles;
      set({
        updateFiles: [
          ...files,
          {
            file: complaintFile.data.url,
            fileName: complaintFile.data.fileName,
            fileType: complaintFile.data.contentType,
            uploadedBy: complaintFile.data.uploadedBy,
            uploadedAt: new Date(),
            complainId: complainId,
          },
        ],
      });
      toast.dismiss(toastId);
      notifySuccess("File Upload Successfully");
    } catch (e: any) {
      toast.dismiss(toastId);
      notifyError(e?.response?.data?.message);
    }
  },

  uploadNatureFile: async (file: any, complainNatureId: number) => {
    const toastId = loader("Uploading File...");
    try {
      const complaintFile =
        await ApiController.getInstance().complainApi.natureAttachment(
          file,
          complainNatureId
        );
      const complaintDetail = get().complaint;
      const natureIndex = complaintDetail.ComplainNatures.findIndex(
        (nature: ComplainNature) => nature.complainNatureId === complainNatureId
      );
      const complaintNature = complaintDetail.ComplainNatures[natureIndex];
      complaintNature.files = complaintFile.data.files;
      set({
        complaint: {
          ...complaintDetail,
          ComplainNatures: [
            ...complaintDetail.ComplainNatures.slice(0, natureIndex),
            complaintNature,
            ...complaintDetail.ComplainNatures.slice(natureIndex + 1),
          ],
        },
      });
      toast.dismiss(toastId);
      notifySuccess("File Upload Successfully");
    } catch (e: any) {
      toast.dismiss(toastId);
      notifyError(e?.response?.data?.message);
    }
  },
  /**
   *
   * @param comment
   */
  addComment: async (comment: ComplainComment) => {
    if (comment?.description?.trim()?.length === 0) {
      notifyError("Comment cannot be empty");
      return;
    }
    const toastId = loader("Adding Comment...");
    try {
      const commentData =
        await ApiController.getInstance().complainApi.createComment(comment);
      set({
        comment: commentData.data,
      });
      toast.dismiss(toastId);
      notifySuccess("Comment added successfully");
    } catch (error: any) {
      toast.dismiss(toastId);
      notifyError(error?.response?.data?.message);
    }
  },
  /**
   *
   * @param commentId
   */
  deleteComment: async (commentId: number) => {
    const toastId = loader("Deleting Comment...");
    try {
      toast.dismiss(toastId);
      notifySuccess("Comment deleted successfully");
    } catch (error: any) {
      toast.dismiss(toastId);
      notifyError(error?.response?.data?.message);
    }
  },

  deleteFilesUploaded: async () => {
    const toastId = loader("Removing File...");
    set({ files: [] });
    toast.dismiss(toastId);
  },

  deleteFile: async (timestamp: string) => {
    const toastId = loader("Deleting File...");
    try {
      set((state: any) => ({
        natureFiles: state.natureFiles.filter(
          (file: any) => file.timestamp !== timestamp
        ),
      }));
      toast.dismiss(toastId);
    } catch (e: any) {
      toast.dismiss(toastId);
      notifyError(e?.response?.data?.message);
    }
  },

  /**
   *
   * @param natureData
   */
  updateDescription: async (natureData: ComplainNature) => {
    const toastId = loader("Updating Description...");
    try {
      const complainNatureData =
        await ApiController.getInstance().complainApi.complainDescription(
          natureData
        );

      const complaintDetail = get().complaint;
      const natureIndex = complaintDetail.ComplainNatures.findIndex(
        (nature: ComplainNature) =>
          nature.complainNatureId === complainNatureData.data.complainNatureId
      );
      const complaintNature = complaintDetail.ComplainNatures[natureIndex];
      complaintNature.otherMetaData = complainNatureData.data.otherMetaData;
      complaintNature.description = complainNatureData.data.description;

      set({
        complaint: {
          ...complaintDetail,
          ComplainNatures: [
            ...complaintDetail.ComplainNatures.slice(0, natureIndex),
            complaintNature,
            ...complaintDetail.ComplainNatures.slice(natureIndex + 1),
          ],
        },
      });
      toast.dismiss(toastId);
      notifySuccess("Description updated successfully");
    } catch (error: any) {
      toast.dismiss(toastId);
      notifyError(error?.response?.data?.message);
    }
  },

  // odrInfo
  updateODRInfoBank: async (odrInfobank: any) => {
    const toastId = loader("Updating Description...");
    try {
      await ApiController.getInstance().complainApi.odrInfo(odrInfobank);
      toast.dismiss(toastId);
      notifySuccess("Record added successfully");
    } catch (error: any) {
      toast.dismiss(toastId);
      notifyError(error?.response?.data?.message);
    }
  },

  uploadOdrDemographic: async (file: any) => {
    const toastId = loader("Uploading File...");
    try {
      await ApiController.getInstance().complainApi.uploadOdrDemographic(file);
      toast.dismiss(toastId);
      notifySuccess("File uploaded and added successfully");
    } catch (error: any) {
      toast.dismiss(toastId);
      notifyError(error?.response?.data?.message);
    }
  },

  getODRHandBook: async (file: any) => {
    const toastId = loader("Fetching Handbook...");
    try {
      const infoBank =
        await ApiController.getInstance().complainApi.handbooks();

      toast.dismiss(toastId);
      return infoBank;
    } catch (error: any) {
      toast.dismiss(toastId);
    }
  },

  redirectUserToODR: async (complaintUUID: any) => {
    const toastId = loader("Redirecting to ODR...");
    try {
      const transferResponse =
        await ApiController.getInstance().complainApi.redirectToOdr(
          complaintUUID
        );

      if (transferResponse.data) {
        toast.dismiss(toastId);
      }
      set({
        redirectionURL: transferResponse.data.url,
      });
    } catch (e: any) {
      console.error(e);
      toast.dismiss(toastId);
      notifyError(e?.response?.data?.message);
    }
  },
  /**
   * Function to call the Server to extend the dispute timeline
   * @param complaintUUID Complaint UUID for which timeline is to be extended
   */
  extendDisputeTimeline: async (complaintUUID: any) => {
    const toastId = loader("Extending Timeline...");
    try {
      const disputeResponse = (
        await ApiController.getInstance().complainApi.extendTimeline(
          complaintUUID
        )
      ).data;

      if (disputeResponse) {
        toast.dismiss(toastId);
      }

      const existingDispute = get().complaint;
      set({
        complaint: {
          ...existingDispute,
          arbitrationExtension: disputeResponse.arbitrationExtension,
          caseInArbitrationDay: disputeResponse.caseInArbitrationDay,
          caseInConciliation: disputeResponse.caseInConciliation,
          conciliationExtension: disputeResponse.conciliationExtension,
        },
      });
      notifySuccess("Timeline extended successfully!");
    } catch (e: any) {
      console.error(e);
      toast.dismiss(toastId);
      notifyError(e?.response?.data?.message);
    }
  },
  /**
   *
   * @returns
   */
  fetchOdrApiKey: async () => {
    const toastId = loader("Redirecting to ODR...");
    try {
      const odrKey = await ApiController.getInstance().complainApi.apiKey();
      if (odrKey.data) {
        toast.dismiss(toastId);
      }
      return odrKey;
    } catch (e: any) {
      console.error(e);
      toast.dismiss(toastId);
      notifyError(e?.response?.data?.message);
    }
  },

  regenerateAPIKey: async () => {
    const toastId = loader("Generating Key.");
    try {
      const odrKey = await ApiController.getInstance().complainApi.regenerate();
      if (odrKey.data) {
        toast.dismiss(toastId);
      }
      return odrKey;
    } catch (e: any) {
      console.error(e);
      toast.dismiss(toastId);
      notifyError(e?.response?.data?.message);
    }
  },

  updateAuthorizedPersonDetails: async (complainObject: Complain) => {
    const toastId = loader("Updating Authorized Person Details...");
    try {
      const odrKey =
        await ApiController.getInstance().complainApi.authorizedPersonDetais(
          complainObject
        );
      if (odrKey.data) {
        toast.dismiss(toastId);
      }
      notifySuccess("Authorized person details updated successfully!");
    } catch (e: any) {
      console.error(e);
      toast.dismiss(toastId);
      notifyError(e?.response?.data?.message);
    }
  },
  getArbitralAward: async (complainId: number) => {
    const toastId = loader("Fetching Arbitral award Details...");
    try {
      const odrKey =
        await ApiController.getInstance().complainApi.getArbitralAward(
          complainId
        );
      toast.dismiss(toastId);
      set({
        arbitralAwardDetails: odrKey.data,
        arbitralFiles: odrKey.data?.files || []
      });
    } catch (e: any) {
      console.error(e);
      toast.dismiss(toastId);
      notifyError(e?.response?.data?.message);
    }
  },
  createArbitralAward: async (awardObject: ArbitrationReport, detailType: string) => {
    const toastId = loader(`Updating ${detailType} Details...`);
    try {
      const odrKey =
        await ApiController.getInstance().complainApi.createArbitralAward(
          awardObject
        );
        set({
          arbitralAwardDetails: odrKey.data,
        });
      if (odrKey.data) {
        toast.dismiss(toastId);
      }
      notifySuccess(`${detailType} details updated successfully!`);
    } catch (e: any) {
      console.error(e);
      toast.dismiss(toastId);
      notifyError(e?.response?.data?.message);
    }
  },
  updateArbitralAward: async (awardObject: ArbitrationReport, detailType: string) => {
    const toastId = loader(`Updating ${detailType} Details...`);
    try {
      const odrKey =
        await ApiController.getInstance().complainApi.updateArbitralAward(
          awardObject
        );
        set({
          arbitralAwardDetails: odrKey.data,
        });
      if (odrKey.data) {
        toast.dismiss(toastId);
      }
      notifySuccess(`${detailType} details updated successfully!`);
    } catch (e: any) {
      console.error(e);
      toast.dismiss(toastId);
      notifyError(e?.response?.data?.message);
    }
  },
  getArbitrators: async (complainId: number) => {
    const toastId = loader("Fetching Arbitral award Details...");
    try {
      const odrKey =
        await ApiController.getInstance().complainApi.getArbitrators(
          complainId
        );
      if (odrKey.data) {
        toast.dismiss(toastId);
      }
      set({
        selectedArbitrators: odrKey.data,
      });
    } catch (e: any) {
      console.error(e);
      toast.dismiss(toastId);
      notifyError(e?.response?.data?.message);
    }
  },
  createArbitrator: async (arbitratorObject: Complain, process: string) => {
    const toastId = loader(`Updating ${process === 'Conciliation' ? 'Conciliator' : 'Arbitrator'} Details...`);
    try {
      const odrKey =
        await ApiController.getInstance().complainApi.createArbitrators(
          arbitratorObject
        );
      if (odrKey.data) {
        set({
          selectedArbitrators: odrKey.data,
        });
        toast.dismiss(toastId);
      }
      notifySuccess(`${process === 'Conciliation' ? 'Conciliator' : 'Arbitrator'} details updated successfully!`);
    } catch (e: any) {
      console.error(e);
      toast.dismiss(toastId);
    }
  },
  /**
   *
   * @param complainObject
   */
  updateNatureType: async (complainObject: ComplainNature) => {
    const toastId = loader("Updating Nature Type Details...");
    try {
      const natureData =
        await ApiController.getInstance().complainApi.updateNatureType(
          complainObject
        );
      if (natureData.data) {
        toast.dismiss(toastId);
      }
      set({
        updatedDisputeNatureType: natureData.data,
      });
      notifySuccess("Nature Type details updated successfully!");
    } catch (e: any) {
      console.error(e);
      toast.dismiss(toastId);
      notifyError(e?.response?.data?.message);
    }
  },

  updateSubStatusofComplain: async (statusObject: any) => {
    const toastId = loader("Updating Status...");
    try {
      const status =
        await ApiController.getInstance().complainApi.updateSubStatus(
          statusObject
        );
      set((state: any) => ({
        complaint: {
          ...state.complaint,
          subStatus: statusObject.subStatus,
        },
      }));
      toast.dismiss(toastId);
      notifySuccess(
        "You have successfully changed the status to " +
        titleCase(statusObject.status)
      );
    } catch (error: any) {
      toast.dismiss(toastId);
      notifyError(error?.response?.data?.message);
    }
  },

  uploadArbitralFile: async (
    awardFileUploadDetails: any,
    arbitrationReportId: number
  ) => {
    const toastId = loader("Uploading File...");
    try {
      const complaintFile =
        await ApiController.getInstance().complainApi.uploadArbitralFile(
          awardFileUploadDetails.file,
          arbitrationReportId,
          awardFileUploadDetails.documentType,
          awardFileUploadDetails.summary,
        );

      const files = get().arbitralFiles || [];
      console.log(files);
      set({
        arbitralFiles: [
          ...files,
          {
            file: complaintFile.data.file,
            fileName: complaintFile.data.fileName,
            fileType: complaintFile.data.contentType,
            uploadedBy: complaintFile.data.uploadedBy,
            timestamp: complaintFile.data.timestamp,
            uploadedAt: new Date(),
            documentType: awardFileUploadDetails?.documentType,
            summary: awardFileUploadDetails?.summary,
            arbitrationReportId: arbitrationReportId,
          },
        ],
      });
      toast.dismiss(toastId);
      notifySuccess("File Upload Successfully");
      return complaintFile;
    } catch (e: any) {
      console.log(e)
      toast.dismiss(toastId);
      notifyError(e?.response?.data?.message);
    }
  },

  getConciliatorAward: async (complainId: number) => {
    const toastId = loader("Fetching Conciliator award Details...");
    try {
      const odrKey =
        await ApiController.getInstance().complainApi.getConciliatorAward(
          complainId
        );
      toast.dismiss(toastId);
      set({
        conciliatorAwardDetails: odrKey.data,
        conciliatorFiles: odrKey.data?.files || []
      });
    } catch (e: any) {
      console.error(e);
      toast.dismiss(toastId);
      notifyError(e?.response?.data?.message);
    }
  },
  createConciliatorAward: async (awardObject: ConciliationReport, detailType: string) => {
    const toastId = loader(`Updating ${detailType} Details...`);
    try {
      const odrKey =
        await ApiController.getInstance().complainApi.createConciliatorAward(
          awardObject
        );
      set({
        conciliatorAwardDetails: odrKey.data,
      });
      if (odrKey.data) {
        toast.dismiss(toastId);
      }
      notifySuccess(`${detailType} updated successfully!`);
    } catch (e: any) {
      console.error(e);
      toast.dismiss(toastId);
      notifyError(e?.response?.data?.message);
    }
  },
  updateConciliatorAward: async (awardObject: ConciliationReport, detailType: string) => {
    const toastId = loader(`Updating ${detailType} Details...`);
    try {
      const odrKey =
        await ApiController.getInstance().complainApi.updateConciliatorAward(
          awardObject
        );
      if (odrKey.data) {
        toast.dismiss(toastId);
      }
      set({
        conciliatorAwardDetails: odrKey.data,
      });
      notifySuccess(`${detailType} details updated successfully!`);
    } catch (e: any) {
      console.error(e);
      toast.dismiss(toastId);
      notifyError(e?.response?.data?.message);
    }
  },

  uploadConciliatorFile: async (
    awardFileUploadDetails: any,
    conciliationReportId: number
  ) => {
    const toastId = loader("Uploading File...");
    try {
      const complaintFile =
        await ApiController.getInstance().complainApi.uploadConciliatorFile(
          awardFileUploadDetails.file,
          conciliationReportId,
          awardFileUploadDetails.documentType,
          awardFileUploadDetails.summary,
        );

      const files = get().conciliatorFiles || [];
      console.log(files);
      set({
        conciliatorFiles: [
          ...files,
          {
            file: complaintFile.data.file,
            fileName: complaintFile.data.fileName,
            fileType: complaintFile.data.contentType,
            uploadedBy: complaintFile.data.uploadedBy,
            timestamp: complaintFile.data.timestamp,
            uploadedAt: new Date(),
            documentType: awardFileUploadDetails?.documentType,
            summary: awardFileUploadDetails?.summary,
            conciliationReportId: conciliationReportId,
          },
        ],
      });
      toast.dismiss(toastId);
      notifySuccess("File Upload Successfully");
      return complaintFile;
    } catch (e: any) {
      console.log(e);
      toast.dismiss(toastId);
      notifyError(e?.response?.data?.message);
    }
  },

});
